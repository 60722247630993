import Image from "next/image";

interface GalleryCardProps {
  image: string;
  name: string;
  updated_at: string;
}

const GalleryCard: React.FC<GalleryCardProps> = ({
  image,
  name,
  updated_at,
}) => {
  return (
    <div className="float-left overflow-hidden group mb-[10px] mr-[.8%] max-h-[250px] relative w-full">
      <div className="h-full">
        <Image
          src={image}
          alt="gallery"
          width={100}
          height={100}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="bg-black text-center group-hover:bottom-0 bg-opacity-80 bottom-[-50px] text-white py-[4px] px-[10px] absolute right-0 transition-all duration-500 ease-in-out w-full">
        <div className="font-bold uppercase">
          <p className="text-white">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default GalleryCard;
