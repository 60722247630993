"use client";

import { useState, useEffect } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import Routes from "@/data/Routes/routes.json";
import { FaInstagram, FaTiktok, FaFacebookF } from "react-icons/fa";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  const pathname = usePathname();

  const isAdsPage =
    pathname.includes("ads") ||
    pathname === "/primary-ads/" ||
    pathname === "/alt-ads-1/";

  const mobileMenu = () => {
    setTimeout(() => {
      setMobileMenuOpen(false);
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const socialMedias = [
    {
      link: "https://www.instagram.com/macspace_service/",
      icon: <FaInstagram />,
    },
    {
      link: "https://www.tiktok.com/@macspace.official",
      icon: <FaTiktok />,
    },
    {
      link: "https://www.facebook.com/profile.php?id=61557244583938&mibextid=ZbWKwL",
      icon: <FaFacebookF />,
    },
  ];
  return (
    <header
      className={`bg-white fixed w-full transition duration-500 z-20 ${
        scroll ? "bg-opacity-100 border-b-2 border-gray-200" : "bg-opacity-0"
      }`}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-start p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link href="/" target="_blank" className="-m-1.5 p-1.5">
            <span className="sr-only">MacSpace.id</span>
            <Image
              className="w-[60%] lg:w-[80%]"
              src={`${
                scroll
                  ? "/images/logo/LOGO MACSPACE.webp"
                  : "/images/logo/LOGO MACSPACE - WHITE.webp"
              }`}
              alt="Logo"
              width={100}
              height={100}
            />
          </Link>
        </div>
        <div className={`flex gap-4 lg:hidden`}>
          {socialMedias.map((item, index) => (
            <Link
              key={index}
              href={item.link}
              className={`text-2xl ${scroll ? "text-black" : "text-white"}`}
            >
              {item.icon}
            </Link>
          ))}
          <button
            type="button"
            className={`-m-2.5 inline-flex ml-6 items-center justify-center rounded-md p-2.5 ${
              scroll ? "text-black" : "text-white"
            }`}
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex" style={{ columnGap: "3rem" }}>
          <Link
            href={"/"}
            target="_blank"
            className={`NavbarClass ${
              pathname === "/" || isAdsPage
                ? "text-red-700"
                : scroll
                ? "text-gray-900"
                : "text-white"
            }`}
          >
            Home
          </Link>
          {Routes.map((item, index) => (
            <Link
              href={item.link}
              target="_blank"
              key={index}
              className={`NavbarClass ${
                pathname.includes(item.link)
                  ? "text-red-700"
                  : scroll
                  ? "text-gray-900"
                  : "text-white"
              }`}
            >
              {item.title}
            </Link>
          ))}
        </Popover.Group>
        <div className="hidden ml-3 lg:flex lg:flex-1 lg:justify-end">
          {socialMedias.map((item, index) => (
            <Link
              key={index}
              href={item.link}
              className={`text-3xl mx-5 ${
                scroll ? "text-black" : "text-white"
              }`}
            >
              {item.icon}
            </Link>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-20" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-20 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="/" target="_blank" className="-m-1.5 p-1.5">
              <span className="sr-only">MacSpace.id</span>
              <Image
                className="h-16 w-auto"
                src="/images/logo/LOGO MACSPACE.webp"
                alt="LOGO"
                width={100}
                height={100}
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root overflow-y-auto overflow-x-hidden">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  href="/"
                  target="_blank"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                    pathname === "/" || isAdsPage
                      ? "text-red-700"
                      : "text-black"
                  }`}
                  onClick={mobileMenu}
                >
                  Home
                </Link>
                {Routes.map((item, index) => (
                  <Link
                    href={item.link}
                    target="_blank"
                    key={index}
                    className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${
                      pathname.includes(item.link)
                        ? "text-red-700"
                        : "text-black"
                    }`}
                    onClick={mobileMenu}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Navbar;
